import Image1 from "../../assets/img/portfolio/project-1.jpg";
import Image2 from "../../assets/img/portfolio/project-2.jpg";
import Image3 from "../../assets/img/portfolio/project-3.jpg";
import Image4 from "../../assets/img/portfolio/project-4.jpg";
import Image5 from "../../assets/img/portfolio/project-5.jpg";
import Image6 from "../../assets/img/portfolio/project-6.jpg";
import Image7 from "../../assets/img/portfolio/project-7.jpg";
import Image8 from "../../assets/img/portfolio/project-8.jpg";
import Image9 from "../../assets/img/portfolio/project-9.jpg";
import Image10 from "../../assets/img/portfolio/project-10.jpg";
import Image11 from "../../assets/img/portfolio/project-11.jpg";
import Image12 from "../../assets/img/portfolio/project-12.jpg";
import Image13 from "../../assets/img/portfolio/project-13.jpg";
import Image14 from "../../assets/img/portfolio/project-14.jpg";
import Image15 from "../../assets/img/portfolio/project-15.jpg";
import Image16 from "../../assets/img/portfolio/project-16.jpg";
import Image17 from "../../assets/img/portfolio/project-17.jpg";

const PortfolioData = [
  {
    id: 1,
    type: "Hand To Hand",
    image: Image1,
    tag: ["app-prototypes"],
    delayAnimation: "0",
    modalDetails: [
      {
        project: "Hand To Hand",
        client: "Academic Project",
        language: "Ionic, HTML, CSS, Javascript",
        //preview: "",
        //link: ""
      },
    ],
  },
  {
    id: 2,
    type: "Vaxjo Mashup",
    image: Image2,
    tag: ["web-development", "app-prototypes"],
    delayAnimation: "0",
    modalDetails: [
      {
        project: "Vaxjo Mashup",
        client: "Academic Project",
        language: "Angular, HTML, CSS, Node.js",
        preview: "Vaxjo Exprlorer/",
        link: "https://mashup-65b63.firebaseapp.com/",
      },
    ],
  },
  {
    id: 3,
    type: "The Dear Goose",
    image: Image3,
    tag: ["web-development", "e-commerce"],
    delayAnimation: "0",
    modalDetails: [
      {
        project: "Website",
        client: "The Dear Goose",
        language: "Wordpress,HTML, CSS, Javascript",
        preview: "www.thedeargoose.gr",
        link: "https://thedeargoose.gr",
      },
    ],
  },
  {
    id: 4,
    type: "Mellow Home",
    image: Image4,
    tag: ["web-development", "e-commerce"],
    delayAnimation: "0",
    modalDetails: [
      {
        project: "Website",
        client: "Mellow Home",
        language: "Wordpress,HTML, CSS, Javascript",
        preview: "www.mellowhome.gr",
        link: "https://mellowhome.gr",
      },
    ],
  },
  {
    id: 5,
    type: "Vands",
    image: Image5,
    tag: ["web-development", "e-commerce"],
    delayAnimation: "0",
    modalDetails: [
      {
        project: "Website",
        client: "VandS",
        language: "Wordpress,HTML, CSS, Javascript",
        preview: "www.vands.gr",
        link: "https://vands.gr",
      },
    ],
  },
  {
    id: 6,
    type: "Doff Don",
    image: Image6,
    tag: ["web-development", "e-commerce"],
    delayAnimation: "0",
    modalDetails: [
      {
        project: "Website",
        client: "Doff Don",
        language: "Wordpress,HTML, CSS, Javascript",
        preview: "www.doffdon.com",
        link: "https://doffdon.gr",
      },
    ],
  },
  {
    id: 7,
    type: "Idees Store",
    image: Image7,
    tag: ["web-development", "e-commerce"],
    delayAnimation: "100",
    modalDetails: [
      {
        project: "Website",
        client: "Idees Store",
        language: "WordPress HTML, CSS, Javascript",
        preview: "www.idees-store.com",
        link: "https://idees-store.gr",
      },
    ],
  },
  {
    id: 8,
    type: "AlbumLab",
    image: Image8,
    tag: ["web-development"],
    delayAnimation: "100",
    modalDetails: [
      {
        project: "Website",
        client: "Album Lab",
        language: "WordPress HTML, CSS, Javascript",
        preview: "www.albumlab.gr",
        link: "https://albumlab.gr",
      },
    ],
  },
  {
    id: 9,
    type: "Fanis Failadis",
    image: Image9,
    tag: ["web-development"],
    delayAnimation: "100",
    modalDetails: [
      {
        project: "Website",
        client: "Fanis Failadis",
        language: "WordPress HTML, CSS, Javascript",
        preview: "www.fanisfailadis.com",
        link: "https://fanisfailadis.com",
      },
    ],
  },
  {
    id: 10,
    type: "G Stravolemos",
    image: Image10,
    tag: ["web-development"],
    delayAnimation: "200",
    modalDetails: [
      {
        project: "Website",
        client: "Giorgos Stravolemos",
        language: "WordPress HTML, CSS, Javascript",
        preview: "www.gstravolemos.com",
        link: "https://gstravolemos.com",
      },
    ],
  },
  {
    id: 11,
    type: "Edit Lab",
    image: Image11,
    tag: ["web-development"],
    delayAnimation: "200",
    modalDetails: [
      {
        project: "Website",
        client: "Edit Lab",
        language: "HTML, CSS, Javascript",
        preview: "www.editlab.gr",
        link: "https://editlab.gr",
      },
    ],
  },
  {
    id: 12,
    type: "Vasiliki Kravvariti Psychotherapy",
    image: Image12,
    tag: ["web-development"],
    delayAnimation: "200",
    modalDetails: [
      {
        project: "Website",
        client: "Vasiliki Kravvariti",
        language: "WordPress HTML, CSS, Javascript",
        preview: "www.vkravvariti.gr",
        link: "https://vkravvariti.gr",
      },
    ],
  },
  {
    id: 13,
    type: "SmartFit Studio",
    image: Image14,
    tag: ["web-development"],
    delayAnimation: "200",
    modalDetails: [
      {
        project: "Website",
        client: "SmartFit Studio",
        language: "WordPress HTML, CSS, Javascript",
        preview: "www.smartfitstudio.gr",
        link: "https://smartfitstudio.gr",
      },
    ],
  },
  {
    id: 14,
    type: "K. Kapiniaris Transporte",
    image: Image13,
    tag: ["web-development"],
    delayAnimation: "200",
    modalDetails: [
      {
        project: "Website",
        client: "Konstantinos Kapiniaris",
        language: "WordPress HTML, CSS, Javascript",
        preview: "www.kapiniaris.de",
        link: "https://kapiniaris.de",
      },
    ],
  },
  {
    id: 15,
    type: "I. Kapiniaris Transporte",
    image: Image16,
    tag: ["web-development"],
    delayAnimation: "200",
    modalDetails: [
      {
        project: "Website",
        client: "Ilias Kapiniaris",
        language: "WordPress HTML, CSS, Javascript",
        preview: "www.kapiniaris.com",
        link: "https://kapiniaris.com",
      },
    ],
  },
  {
    id: 16,
    type: "Brill",
    image: Image15,
    tag: ["web-development"],
    delayAnimation: "200",
    modalDetails: [
      {
        project: "Website",
        client: "Arvanitis Antonis, Tzalamoura Alexia",
        language: "WordPress HTML, CSS, Javascript",
        preview: "www.brill.com",
        link: "https://brill.com",
      },
    ],
  },
  {
    id: 17,
    type: "Trash Can Management",
    image: Image17,
    tag: ["mockup"],
    delayAnimation: "200",
    modalDetails: [
      {
        project: "IoT simulation",
        client: "Academic Project",
        language: "Losant, Iotify, Twillio, Python",
        //preview: "",
        //link: "",
      },
    ],
  },
];

export default PortfolioData;
