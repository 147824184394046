import React from "react";

const SocialShare = [
  {
    iconName: "fa fa-github", link: "https://github.com/ChrisGk89",
  },
  { iconName: "fa fa-twitter", link: "https://twitter.com/Chris_gk89" },
  { iconName: "fa fa-linkedin", link: "https://www.linkedin.com/in/christos-gkalfas" },
  { iconName: "fa fa-skype", link: "https://join.skype.com/invite/enaiym2OlON7"},
  //{
 //  iconName: "fa fa-youtube",
 //   link: "https://www.youtube.com/",
  //},
  //{ iconName: "fa fa-dribbble", link: "https://dribbble.com/" },
];

const Social = () => {
  return (
    <ul className="social list-unstyled pt-1 mb-5">
      {SocialShare.map((val, i) => (
        <li key={i}>
          <a href={val.link} target="_blank" rel="noreferrer">
            <i className={val.iconName}></i>
          </a>
        </li>
      ))}
    </ul>
  );
};

export default Social;
