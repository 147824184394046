import { useState } from "react";
import img1 from "../assets/img/blog/blog-post-1.png";
import img2 from "../assets/img/blog/blog-post-2.png";
import img3 from "../assets/img/blog/blog-post-3.png";
//import img4 from "../assets/img/blog/blog-post-4.png";
//import img5 from "../assets/img/blog/blog-post-5.png";
//import img6 from "../assets/img/blog/blog-post-6.png";

const AllBlogData = () => {
  const blogsData = [
    {
      id: 1,
      img: img1,
      title: "10 Best Web Development Frameworks",
      commentor: "hackr.io ",
      date: "07 June 2022",
      tag: `web-development, frameworks, backend, frontend`,
      link: "shorturl.at/eloxz",
      description1:
        "1. Express: Thanks to the skyrocketing popularity of Node.js, Express is quickly becoming one of the most trending best framework for web development nowadays. It is popular amongst Accenture, IBM and Uber, and many other companies, and it’s also compatible with other frameworks such as Kraken, Sails, and Loopback. Express prides itself as a minimal, fast, and unopinionated framework. It provides some core framework functionalities without obscuring the features of Node and leverages the robust performance of the asynchronous Node.js. It’s also quite flexible and supports full applications as well as REST API, as well. Perhaps the biggest drawback of Express is the fact that there’s no defined way of doing things, at least for beginners. Express GitHub Link: https://github.com/expressjs/express",
      description2:
        "From RESTful APIs to complete MVC frameworks, here are some of the most robust backend frameworks in 2022 that you might be interested to learn. ",
      description3:
        "2. Django: Django is a Model-View-Template framework that uses Python for web development. Big names such as Google, Youtube, and Instagram use this framework. Django boasts its batteries-included feature, which is a bunch of features such as authentication and messaging that Django has. It follows the Convention Over Configuration pattern and the DRY pattern as well. Security in Django is essential. Django provides techniques and tools for developers to build a secure website or implements the security features in the framework itself, like preventing code execution in the template layer. Here is our detailed post on Django: What is Django? Advantages and Disadvantages of using Django. Django Github Link: https://github.com/django/django",
      description4:
        "3. Rails: Rails is a Model-View-Controller framework that uses Ruby, and it’s a popular framework that is loved by many developers. Airbnb, GitHub, Hulu, and Shopify are primary users of Rails. Rails is considered a beginner-friendly framework, and the fact pros and cons are debated, helps beginners get started with web development quite quickly. There are many useful gems for rails, which are library-like dependencies that extend the functionalities of your application and helps you develop even faster and more efficiently. The Rails community is quite reliable and friendly, and there are many tutorials, screencasts, and resources that can help you become a rails expert in no time. The main disadvantage of rails lies in the fact that they take quite some effort to deploy and run in a production environment, and the learning curve of rails becomes steep once you dive deeper into the framework to unravel the magic behind it. Rails Github Link: https://github.com/rails/rails",
      description5:
        "4. Laravel: Laravel is a Model-View-Controller framework that uses PHP, which is one of the most popular languages of the web. It’s relatively young compared to other frameworks on this list. Laravel comes with API support out of the box, and it also possesses a decent amount of packages that could extend its reach. Laracasts is a screencasts tutorials website with over a thousand videos on PHP, Laravel, and frontend technologies in the Laravel ecosystem that could be considered to be a beginner’s heaven. In terms of performance, however, Laravel doesn’t compare to Django or Express, which might be a drawback for massive projects. Laravel Github Link: https://github.com/laravel/laravel. Want to master Laravel? PHP with Laravel for beginners is a great online course.",
      description7:
        "5. Spring: Spring is a Model-View-Controller framework that uses Java, the all-time popular language. Websites like Wix, TicketMaster, and BillGuard are users of this framework. Spring possesses many sister projects that boost its performance and let you scale your business quickly. The fact that it uses Java, a strongly typed language, is a severe pro to many web developers. The learning curve might be quite steep tho, especially if you don’t know Java. Spring Github Link: https://github.com/spring-projects/spring-framework",
      description8:
        "6. Angular: Angular is a front-end framework that specializes in building rich Single-Page applications. It’s a vibrant framework able to build complete client-side applications, and there’s so much to do and learn in Angular. Angular 1.x used Javascript, but later releases adopted Typescript, which is a superset of Javascript. Angular’s main cons are its size compared to other frameworks, and the fact it’s not SEO friendly by nature, though it can be SEO optimized. Google developed angular, and Google, Microsoft, and Paypal use it.",
      description9:
        "7. React: React is not a framework, it’s a frontend library, but many developers consider it a framework and it’s usually compared in that context. React was the first to adopt the component-based architecture that Angular and Vue, and many other frameworks started to adopt later on. React’s virtual dom makes the dom-manipulation much faster, and it’s quite easy to pick up, mainly thanks to its JSX syntax. React could be used server-side or client-side. It was developed and maintained by Facebook, and Facebook and Instagram use it.",
      description10:
        "8. Vue: Vue.js is the new rising star; it started as an individual project and quickly grew into becoming one of the most trending JS frameworks out there. There are many cool things about Vue. First, it’s a progressive framework, which means that if you have an existing project, you can adopt Vue for one portion of the project, and everything would work just fine. Second, it also brings along the component architecture to play, and the Vue ecosystem can help you build complete frontend applications. Some people are wary of using Vue since big companies like Facebook or Google do not support it, but that’s quickly changing as big names are starting to invest in Vue",
      description11:
        "9. Ember: Ember was named the best Javascript framework back in 2015. Today, the Ember community is enormous, and it’s ever-expanding, with new features, and releases added regularly. Ember possesses the two-way data binding that Angular boasts, and it comes with a lot of features and components that you can use out of the box. Google, Microsoft, Heroku, and Netflix use this framework often. Ember revolves around the developer’s productivity and attempts to maximize it by either eliminating the need for time-wasting activities or adopting some JS best practices in its core design.",
    },
    {
      id: 2,
      img: img2,
      title: "Top 10 Deep Learning Frameworks for 2022",
      commentor: "EM360 TECH ",
      date: "5 July 2022",
      tag: `deep learning, machine learning, tech`,
      link: "shorturl.at/hEKT5",
      description1:
        "Artificial Intelligence is quickly making its way into the mainstream for many companies. Whether you’re leveraging machine learning tools for your data management strategy, or using intelligent insights to learn more about your customers, you’re likely investing in AI somewhere. ",
      description2:
        "One of the valuable sub-sections of the AI and Machine Learning (ML) landscape gaining rapid attention today is “deep learning”. With deep learning frameworks, companies can create a wide variety of powerful tools, bots, and services. Currently, the market for deep learning is growing at a rate of 34.3% per year- faster than most technologies. ",
      description3:
        "The question is, with so many deep learning solutions in the market today, which offerings are most worthy of your attention? Here are the top 10 deep learning frameworks worth considering now. ",
      description4:
        "1. TensorFlow, One of the better-known deep learning platforms on the market, TensorFlow is an end-to-end open source solution for powering any machine learning strategy. It’s made up of a comprehensive ecosystem of libraries, tools, and community resources to help developers easily build and deploy powerful applications in their field. Created by Google, TensorFlow is a JavaScript-based framework, with easy solutions for deploying models on browsers. You can build and train your models using high-level APIs, and even produce ML offerings for the cloud, on-prem, and on-device ecosystem. A simple architecture helps anyone bring ideas to life faster.",
      description5:
        "2. Chainer, Chainer is an intuitive, flexible, and powerful framework for connecting and transforming neural networks. The solution conveniently bridges the gap between implementations of deep learning and algorithms, with CUDA computation included. It only takes a few lines of code to leverage a GPU, and the intuitive environment makes it easy to control flow statements in Python. As a flexible environment for deep learning, Chainer supports various network architectures, including recurrent nets, convnets, and feed-forward nets, as well as per-batch architectures. There’s plenty of helpful documentation to get you started too.",
      description6:
        "3. PyTorch, A phenomenal example of the power of open-source machine learning frameworks, PyTorch helps to accelerate the potential of any project, from research through to production deployment. With PyTorch, companies gain access to a production-ready environment where they can seamlessly transition between graph and eager models with Torchscript. There’s a scalable and distributed training system, as well as tools for performance optimization and research. What’s more, a robust ecosystem of tools and libraries makes it easy to extend your PyTorch experience and unlock new creations in computer vision, natural language processing, and other areas.",
      description7:
        "4. Keras, One of the better-known deep learning environments for Python coders, Keras benefits from being simple, flexible, and versatile. This powerful tool can run on top of existing environments like Tensorflow and Microsoft Cognitive Toolkit. The offering consistently follows best practices for minimizing cognitive load, offers simple and consistent APIs, and minimizes the number of user actions required for common use cases. One of the biggest selling points of Keras is its phenomenal speed. The system comes with built-in solutions for data parallelism, and can process massive volumes of data in no time. You can accelerate your training time for models with fast-paced solutions. There are also extensive developer and documentation guides available. ",
      description8:
        "5. Sonnet, Created by the experts at DeepMind, Sonnet is a powerful library and framework created to build complex neural network structures via TensorFlow. This deep learning framework is actually built on top of the TensorFlow environment, taking full advantage of the functionality offered by Google. With Sonnet, business leaders can develop and create the primary Python objects connected to specific parts of a neural network. From there, you can connect your objects to your computational Tensorflow graph, to create a variety of powerful tools. The overall solution is streamlined and intuitive, perfect for companies hoping to create innovative machine learning projects quickly.",
      description9:
        "6. Gluon, Created as part of a collaboration between Microsoft and AWS (Amazon Web Services), Gluon is an easy-to-use interface for deep learning projects. The solution was designed to improve the flexibility, accessibility, and speed of using machine learning technologies for all developers. The Gluon interface is an open-source library within the Apache MXNet space, which adapts to support the needs of developers of all skill levels. Gluon delivers a straightforward and effective API for defining deep learning and machine learning models with an arrangement of pre-built neural network components. There are a complete range of plug-and-play building blocks to work with too, so you can take your solution to market faster.",
      description10:
        "7. ONNX, ONNX, or the Open Neural Network Exchange is the result of a collaboration between Facebook (now Meta) and Microsoft. The solution provides developers with an open ecosystem for the development and presentation of deep learning and ML models. The benefits of the system include interoperability with a range of interface engines, and easy hardware access. With the ONNX environment, users have access to an computation graph model, along with various definitions of built-in standard data types and operators. ONNX also helps to simplify the process of moving models between different modes of working with AI.",
      description11:
        "8. DeepLearning4J, A suite of tools created to aid developers in running deep learning models in the JVM, Eclipse Deeplearning4J is the only framework which allows users to train models from Java while interoperating with the python environment. The use cases for this solution include retraining and importing models, and deploying JVM within various micro service environments. Deeplearning4J comes with a range of submodules to help get you started, as well as a huge selection of knowledgebase articles and documentation options. The open-source environment offers excellent levels of flexibility.",
      description12:
        "9. H2O, Another phenomenal opensource solution for machine learning, deep learning, and predictive analytics, H2O makes ML innovations more accessible to the masses. The technology is supported by a leading company in the machine learning space, and provides data science teams with the technology they need to build, deploy, and manage scalable predictive models. The framework comes with machine learning algorithms, as well as pre-built models for beginners. There are also visualisation tools which assist developers in creating models for data. H2O uses a unique kind of architecture which also leverages distributed computing to manage and leverage data at an incredible scale.",
      description13:
        "10. MxNet, Otherwise known as Apache MxNet, MxNet is a leading deep learning framework used for flexible research production and prototyping. The hybrid front-end environment transitions between Eager and Gluon imperative mode, and symbolic mode for speed and flexibility. You’ll also have access to 8 different language bindings to choose from, including Java and Python. MxNet is packed full of useful capabilities, including distributed training for scalable operations and performance optimization. There’s also a thriving ecosystem of tools and libraries to help your discover new use cases and opportunities for growth.",
    },
    {
      id: 3,
      img: img3,
      title: "Everything You Need to Know About Web Accessibility",
      commentor: "steve ",
      date: "9 January 2020",
      tag: `wordpress, business, economy, design`,
      link: 'shorturl.at/exGS8',
      description1:
        "What is Web Accessibility? Before we dive into what web accessibility is, we should first understand what the word ‘Accessibility’ entails. From my knowledge, “Accessibility means the ability of everyone regardless of their condition to have access to something (e.g internet, transport system).” Wikipedia says, “ Accessibility refers to the design of products, devices, services, or environments for people who experience disabilities.” So we could eventually say the Web Accessibility is a way to make everyone including the disabled to have access to the web and internet in whole. To put it differently, we could also say: Web accessibility means that people with disabilities can use the Web. At the end of the day, we can conclude that people with disabilities can perceive, understand, navigate, and interact with the Web, and that they can contribute to the Web. Web accessibility also benefit those with temporary or conditional disabilities which in some cases maybe aging, slow internet connection, broken arm, e.t.c. To better understand this we need to elaborate on types of disabilities.",
      description2: "Making the web accessible by everyone…",
      description3:
        "What is Disability And Types. Disability simply means a situation or condition that hinders you from doing something you could easily do if you are not in that condition. According to cdc, A disability is any condition of the body or mind (impairment) that makes it more difficult for the person with the condition to do certain activities (activity limitation) and interact with the world around them (participation restrictions). There are three Modes of Disability: Permanent Disability: This is when you are completely disabled. Example: blind, deaf and so. Temporary Disability: This is simply a physical or mental disability which hinders your discharging of responsibilities for a short period of time. Conditional or Situation Disability: This is simply when you are not able to do things due to the situation you find yourself in. Example: slow internet connection.",
      description4:
        "Why Web Accessibility is Important? The Web and Internet in whole is an increasingly important resource in many aspects of our life which includes: education, employment, government, commerce, health care, recreation, and more. It is important that the Web be accessible to everyone in order to provide equal access and equal opportunity to people with disabilities. An accessible Web can help people with disabilities participate more actively in society. Also an accessible website is often the one of the easiest ways to do business with many people with disabilities, for instance, people who cannot read print material, people who have difficulty going to a physical store or mall, and others. Furthermore, what you do for accessibility overlaps with other best practices such as mobile Web design, usability, and search engine optimization (SEO). An accessible website gives the access to information and interaction for many people with disabilities. That is, the accessibility barriers to print, audio, and visual media can be much more easily overcome through Web technologies. I suggest reading “Social Factors in Developing a Web Accessibility Business Case for Your Organization” which shows how the Web impacts the lives of people with disabilities and Web accessibility as an aspect of corporate social responsibility. Another important consideration for organizations is that Web accessibility is required by laws and policies in some cases. WAI Web Accessibility Policy Resources links to resources for addressing legal and policy factors within organizations, including a list of relevant laws and policies around the world.",
      description5:
        "How to Make Web Accessible? Making the web accessible depends on the developers building for the web. Making your website accessible to people with disabilities, will end up making it accessible to everyone. The use of Alt-tags. The alt-tag or alternative (alt) html attribute is basically used to describe an image. In HTML, it looks the same thing like this:  <img src =”image-location” alt=”this is where your image description goes”> Even if you are using a visual tool that hides your HTML code to build website, you will always have a chance to enter an image description.  Of course the (alt=” “) can be empty but it would be better to give each a helpful description for screen readers. Assuming you were blind, what would you need to know about the image? “Woman” isn’t much help, but maybe “Woman drawing design flow chart including accessibility, usability, branding, and design” with the use of screen readers would be better.",
      description6:
        "Use Better Tables: You should add captions to your tables using the caption tag in order to make them easier to understand by screen readers rather than just making the title of a table in bold text.You should also add the “scope” element and neatly label new rows and columns in your table as seen above so that screen readers don’t simply rattle off a series of table cells without giving any context.",
      description7:
        "Keyboard Navigation:  By default anything you put on your website should be something that could be conceivably carried out using a keyboard only. Which means you shouldn’t mess with your navigation buttons. For instance don’t animate navigation buttons as dropdown buttons if you can’t use them with a screen reader.",
      description8:
        "Use Default HTML Tags: Please for web accessibility, don’t mess with the default html tags. Use buttons for buttons not anchors for buttons. To make anchors work the same way as buttons you need to add additional javascript event which is a very dumb approach when you could easily use the normal naked button. Use buttons for buttons, anchors for links, “table, tbody, td, th” for tables, “h1, h2, h3, …” for titles and so on. Messing up with them will confuse screen readers entirely because all screen readers follows a particular way to read the elements on a web page.",
      description9:
        "Closed Captions for Media: The videos or audio elements on your website, should have captions. Closed captions are very useful not only for accessibility (permanently and temporarily disabled users) but also for users who may be using your website somewhere where they can’t play audio, such as in an office or in a noisy location. Consider providing a text transcript for podcasts or other audio elements. The text transcript will not only make it easier for Google and other search engines to index the content and help your Google ranking, but also useful for people who can’t listen to the audio. Having captions on your media files will make it accessible to disabled users which will eventually be accessible by everyone.",
      decription10:
        "Use the ARIA Tag. According to MDN: Accessible Rich Internet Applications (ARIA) is a simple set of HTML attributes that define ways to make Web content and Web applications (especially those developed with Ajax, JavaScript and more recent web technologies like Bootstrap) more accessible to people with disabilities.For instance, ARIA enables accessible navigation landmarks, JavaScript widgets, form hints and error messages, live content updates, and more. ARIA has a set of special accessibility HTML attributes which can be added to any markup, but is especially suited to HTML. The role attribute defines a specific role for type of object (such as an article, alert, slider or a button). Additional ARIA attributes provide other useful properties, such as a description for a form or the current width of a progressbar. ARIA attributes can also be used to specify active or disabled state for objects(especially buttons). ",
      description11:
        "Always Use the Title Tag: Browsers don’t always display the HTML title tag in the webpage body, but it is helpful for screen readers and tabs title. So ensure each of your website’s pages has a descriptive but short title that tells visitors what the page is all about.",
    },
    /*{
      id: 4,
      img: img4,
      title: "How to Inject Humor & Comedy Into Your Brand",
      commentor: "Beker ",
      date: "15 March 2022",
      tag: `wordpress, business, economy, design`,
      description1:
        "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Suspendisse interdum consectetur libero id faucibu nisl. Lacus vel facilisis volutpat est velit egestas",
      description2:
        "Most photographers find it hard to see interesting pictures in places in which they are most familiar. A trip somewhere new seems always exactly what our photography needed, as shooting away from home consistently inspires us to new artistic heights. ",
      description3:
        "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.",
      description4:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      id: 5,
      img: img5,
      title: "Women in Web Design: How To Achieve Success",
      commentor: "Janntul ",
      date: "9 January 2021",
      tag: `wordpress, business, economy, design`,
      description1:
        "Jorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
      description2:
        "Most photographers find it hard to see interesting pictures in places in which they are most familiar. A trip somewhere new seems always exactly what our photography needed, as shooting away from home consistently inspires us to new artistic heights. ",
      description3:
        "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.",
      description4:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
      id: 6,
      img: img6,
      title: "Evergreen versus topical content: An overview",
      commentor: "Hasan ",
      date: "9 January 2022",
      tag: `wordpress, business, economy, design`,
      description1:
        "Still ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
      description2:
        "Most photographers find it hard to see interesting pictures in places in which they are most familiar. A trip somewhere new seems always exactly what our photography needed, as shooting away from home consistently inspires us to new artistic heights. ",
      description3:
        "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.",
      description4:
        "Still ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },*/
  ];

  const [singleData, setSingleData] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  const handleBlogsData = (id) => {
    const find = blogsData.find((item) => item?.id === id);
    setSingleData(find);
    setIsOpen(true);
  };

  return {
    singleData,
    isOpen,
    setIsOpen,
    blogsData,
    handleBlogsData,
  };
};

export default AllBlogData;
