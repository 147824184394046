import React from "react";

const personalInfoContent = [
  { meta: "first name", metaInfo: "Christos" },
  { meta: "last name", metaInfo: "Gkalfas" },
  //{ meta: "Age", metaInfo: "27 Years" },
  //{ meta: "Nationality", metaInfo: "Tunisian" },
  { meta: "Open to work / Freelance", metaInfo: "Currently on full-time job" },
  { meta: "Address", metaInfo: "Greece" },
  { meta: "phone", metaInfo: "+30 697 2637 826" },
  { meta: "Email", metaInfo: "info@chrisgkalfas.com" },
  { meta: "Skype", metaInfo: " chris_gk1" },
  { meta: "langages", metaInfo: "Greek - Native, English - C2" },
];

const PersonalInfo = () => {
  return (
    <ul className="about-list list-unstyled open-sans-font">
      {personalInfoContent.map((val, i) => (
        <li key={i}>
          <span className="title">{val.meta}: </span>
          <span className="value d-block d-sm-inline-block d-lg-block d-xl-inline-block">
            {val.metaInfo}
          </span>
        </li>
      ))}
    </ul>
  );
};

export default PersonalInfo;
