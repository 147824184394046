import React from "react";

const educationContent = [
  {
    year: "2020 - 2025",
    degree: "MASTER DEGREE",
    institute: "STOCKHOLM UNIVERSITY",
    details: `Open e-Government / IT Project Management`,
  },
  {
    year: "2017 - 2019",
    degree: "MASTER DEGREE",
    institute: "LINNAEUS UNIVERSITY",
    details: `Social Media and Web Technologies`,
  },
  {
    year: "2008 - 2013",
    degree: "BACHELOR DEGREE ",
    institute: "UNIVERSITY OF THESSALY (ex. TECHNOLOGICAL INSTITUTE OF LARISA)",
    details: `Software Engineering and Telecommunications`,
  },
];

const Education = () => {
  return (
    <ul>
      {educationContent.map((val, i) => (
        <li key={i}>
          <div className="icon">
            <i className="fa fa-briefcase"></i>
          </div>
          <span className="time open-sans-font text-uppercase">{val.year}</span>
          <h5 className="poppins-font text-uppercase">
            {val.degree}
            <span className="place open-sans-font">{val.institute}</span>
          </h5>
          <p className="open-sans-font">{val.details}</p>
        </li>
      ))}
    </ul>
  );
};

export default Education;
