import React from "react";

const experienceContent = [
  {
    year: "Mar 2023 - Present",
    position: "B2C Commerce / Front - End Developer",
    compnayName: "Deloitte Digital",
    details: `Developing front-end experiences on a global scale, utilizing B2C Commerce Cloud on Salesforce. Collaborating with utterly talented front & back-end developers along with functional requirements specialists, delivering exceptional results to multinational stakeholders and product owners. Committed to industry best practices, requirement standards and cutting-edge technologies.
    `,
  },
  {
    year: "Feb 2015 - Mar 2023",
    position: " Web Developer",
    compnayName: "Freelancer",
    details: ` Building numerous user and mobile friendly e-Commerce and personal websites creating brand awareness and increasing their web traffic from 150% to 200% and sales from 35% to 70% according to short term clients’ data.
    • Built sustainability oriented Ionic and Web applications for academic purposes. The conducted surveys were 87% positive that the prototype applications could create great impact.`,
  },
  {
    year: "Sep 2013 - July 2014",
    position: "Motorized Infantry Battalion Personnel",
    compnayName: "Greek Armed Forces",
    details: `Motorized infantry battalion training prepares soldiers for combat operations through a mix of physical fitness, weapons proficiency, and tactical skills.
    Teamwork and coordination among soldiers are emphasized.`
  },
  {
    year: "Oct 2011 & Sep 2012 - March 2013",
    position: "Lab Technical Assistant / Internship",
    compnayName: "University of Thessaly, Larisa Water and Sewage Company",
    details: `C# warehouse logistics project including all the data manipulation.Extensive simulations of the mobile networks reliability using Hata-Okumura models on Matlab. IT Technical Infrastructure Support. Using Telematics to calibrate the pressure and flow of the watering and sewage system.
    `,
  },
];

const Experience = () => {
  return (
    <ul>
      {experienceContent.map((val, i) => (
        <li key={i}>
          <div className="icon">
            <i className="fa fa-briefcase"></i>
          </div>
          <span className="time open-sans-font text-uppercase">{val.year}</span>
          <h5 className="poppins-font text-uppercase">
            {val.position}
            <span className="place open-sans-font">{val.compnayName}</span>
          </h5>
          <p className="open-sans-font">{val.details}</p>
        </li>
      ))}
    </ul>
  );
};

export default Experience;
